@media only screen and (max-width: 768px) {
	.ProductsMain {
		text-align: center;
	}
	.Products {
		margin-left: 5px;
		margin-right: 5px;
		text-align: center;
		display: flex;
    flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
	}
	.brand-buttons {
		display: flex;
	}
	.filters {
		margin-bottom: 2%;
	}
	.brand-button-image {
		width: 100%;
	}
	.brand-header {
		width: 300px;
		height: 50px;
		margin-bottom: 20px;
	}

	.brand-button {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 14px;
		color: black;
		margin: 4px;
	}
	.brand-button-selected {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 15px;
		color: black;
		margin: 4px;
		border-bottom: 4px solid #33415b;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.ProductsMain {
		text-align: center;
	}
	.Products {
		margin-left: 5px;
		margin-right: 5px;
		text-align: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
	}
	.brand-buttons {
		display: flex;
	}
	.filters {
		margin-bottom: 2%;
	}
	.brand-button-image {
		width: 100%;
	}
	.brand-header {
		width: 700px;
		height: 150px;
		margin-bottom: 20px;
	}

	.brand-button {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 14px;
		color: black;
		margin: 4px;
	}
	.brand-button-selected {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 15px;
		color: black;
		margin: 4px;
		border-bottom: 4px solid #33415b;
	}
}
@media only screen and (min-width: 1024px) {
	.ProductsMain {
		text-align: center;
	}
	.Products {
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}
	.brand-buttons {
		display: flex;
	}
	.filters {
		margin-bottom: 2%;
	}
	.brand-button-image {
		width: 100%;
	}
	.brand-header {
		width: 750px;
		height: 150px;
		margin-bottom: 20px;
	}

	.brand-button {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 14px;
		color: black;
		margin: 4px;
	}
	.brand-button-selected {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 15px;
		color: black;
		margin: 4px;
		border-bottom: 4px solid #33415b;
	}
	.brand-button:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
