.WhatWeDo {
  margin: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.WhatWeDo h1 {
  text-align: center;
}
.WhatWeDoList {
  text-align: left;
}
.image-grid {
  display: flex;
  flex-direction: column;
  height: 68.6vh;
  text-align: center;
}
.top-row {
  display: flex;
  justify-content: space-evenly;
  height: 50%;
}
.bottom-row {
  display: flex;
  justify-content: space-evenly;
  height: 50%;
}
.top-row div {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.bottom-row div {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.image-grid img {
  max-width: 100%;
  max-height: 70%;
}
.WLButton {
	background-color: white;
  margin-bottom: 2%;
	border: none;
	width: 10vw;
	height: 3vh;
	transition-duration: 0.6s;
	font-family: "Cardo", serif;
	font-weight: 550;
	font-size: 0.7vw;
	color: black;
}
.WLButton:hover {
	background-color: #33415b;
	cursor: pointer;
	color: white;
}