@media only screen and (max-width: 830px) {
	.AboutUs {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		text-align: center;
	}
  .AboutUsText {
    width: 70%;
    font-size: 20px;
  }
	.image {
		width: 100%;
	}
}
@media only screen and (min-width: 830px) and (max-width: 1024px) {
	.AboutUs {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
		text-align: center;
	}
  .AboutUsText {
    width: 40%;
    font-size: 25px;
  }
	.image {
		width: 400px;
		margin-right: 5%;
		margin-left: 5%;
	}
}
@media only screen and (min-width: 1024px) {
	.AboutUs {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
		text-align: center;
	}
  .AboutUsText {
    width: 40%;
    font-size: 25px;
  }
	.image {
		width: 50%;
		margin-right: 5%;
		margin-left: 5%;
	}
}
