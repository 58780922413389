@media only screen and (max-width: 768px) {
	.terpenes {
    padding-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: white;
		width: 100%;
		height: 100%;
	}
	.graphics {
		position: relative;
		height: 60%;
		justify-content: center;
		align-items: center;
	}
	.text-under-graphics {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;
		height: 40%;
	}
	.under-image-box {
		display: flex;
		flex-direction: column;
    justify-content: center;
		align-items: center;
		width: 50%;
		height: 40%;
	}
	.leaflyButton {
		background-color: white;
		border: none;
		width: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 12px;
		color: black;
	}
	.leaflyButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.terpene-type {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-name {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-price {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.selectForm {
		width: 50%; /* Full width */
		padding: 6px; /* Some padding */
		border: 1px solid #ccc; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
	.TARButtons {
    display: flex;
    flex-direction: row;
		font-size: 150%;
	}
	.TARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.TARButton:hover {
		cursor: pointer;
	}
	.terpeneButton {
		background-color: white;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: medium;
		color: black;
		margin: 4px;
	}
	.terpeneButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}

	.terpene-bottle {
		display: block;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.name-and-price {
		height: 20%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		padding: 2%;
	}
	.image-text {
		position: absolute;
		top: 75%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 20%;
		text-align: center;
	}
	.addTerpToCart {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 50%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
		.terpenes {
    padding-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: white;
		width: 100%;
		height: 100%;
	}
	.graphics {
		position: relative;
		height: 70%;
		justify-content: center;
		align-items: center;
	}
	.text-under-graphics {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;
		height: 30%;
	}
	.under-image-box {
		display: flex;
		flex-direction: column;
    justify-content: center;
		align-items: center;
		width: 50%;
		height: 40%;
	}
	.leaflyButton {
		background-color: white;
		border: none;
		width: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 12px;
		color: black;
	}
	.leaflyButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.terpene-type {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-name {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-price {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.selectForm {
		width: 70%; /* Full width */
		padding: 6px; /* Some padding */
		border: 1px solid #ccc; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
	.TARButtons {
    display: flex;
    flex-direction: row;
		font-size: 150%;
	}
	.TARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.TARButton:hover {
		cursor: pointer;
	}
	.terpeneButton {
		background-color: white;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: medium;
		color: black;
		margin: 4px;
	}
	.terpeneButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}

	.terpene-bottle {
		display: block;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.name-and-price {
		height: 20%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		padding: 2%;
	}
	.image-text {
		position: absolute;
		top: 75%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 20%;
		text-align: center;
	}
	.addTerpToCart {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 50%;
	}
}
@media only screen and (min-width: 1024px) {
	.terpenes {
    padding-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: white;
		width: 100%;
		height: 100%;
	}
	.graphics {
		position: relative;
		height: 70%;
		justify-content: center;
		align-items: center;
	}
	.text-under-graphics {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;
		height: 30%;
	}
	.under-image-box {
		display: flex;
		flex-direction: column;
    justify-content: center;
		align-items: center;
		width: 50%;
		height: 40%;
	}
	.leaflyButton {
		background-color: white;
		border: none;
		width: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 12px;
		color: black;
	}
	.leaflyButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.terpene-type {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-name {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-price {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.selectForm {
		width: 80%; /* Full width */
		padding: 6px; /* Some padding */
		border: 1px solid #ccc; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
	.TARButtons {
    display: flex;
    flex-direction: row;
		font-size: 150%;
	}
	.TARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.TARButton:hover {
		cursor: pointer;
	}
	.terpeneButton {
		background-color: white;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: medium;
		color: black;
		margin: 4px;
	}
	.terpeneButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}

	.terpene-bottle {
		display: block;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.name-and-price {
		height: 20%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		padding: 2%;
	}
	.image-text {
		position: absolute;
		top: 75%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 20%;
		text-align: center;
	}
	.addTerpToCart {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 50%;
	}
}
