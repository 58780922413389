.MixCalculator {
	text-align: center;
	display: flex;
	flex-direction: column;
	flex-wrap: none;
	align-items: center;
}
@media only screen and (max-width: 768px) {
	.Calculator {
		width: 100%;
		border: 1px solid black;
		padding: 2%;
		text-align: left;
		display: flex;
		flex-direction: row;
	}
	.Results {
		width: 50%;
		font-size: 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
	.Inputs {
		font-size: 10px;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.Calculator {
		width: 80%;
		border: 1px solid black;
		padding: 2%;
		text-align: left;
		display: flex;
		flex-direction: row;
	}
	.Results {
		width: 50%;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
	.Inputs {
		font-size: 15px;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
@media only screen and (min-width: 1024px) {
	.Calculator {
		width: 60%;
		border: 1px solid black;
		padding: 2%;
		text-align: left;
		display: flex;
		flex-direction: row;
	}
	.Results {
		width: 50%;
		font-size: 25px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
	.Inputs {
		font-size: 20px;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.InputBox {
	display: flex;
	flex-direction: column;
	text-align: center;
}
.inputNumber {
	display: flex;
	justify-content: center;
}
.decimalInput {
	font-size: 25px;
	width: auto;
	text-align: right;
	border: none;
}
.integerInput {
	font-size: 25px;
	width: auto;
	text-align: right;
	border: none;
}
.Inputs span {
	font-size: 25px;
}
.Inputs input::-webkit-outer-spin-button,
.Inputs input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.Inputs input[type="number"] {
	-moz-appearance: textfield;
}

.Result {
	display: flex;
}
.Volumes {
	width: 50%;
}
.VolumesResults {
	padding: 2px;
	width: 50%;
	border: 2px solid #556c97;
}
.CalcButton {
	margin-top: 2%;
	width: 30%;
}
.inputButton {
	border: none;
	font-size: 20px;
}
