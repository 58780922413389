@media only screen and (max-width: 768px) {
	.TerpenesLearn {
		text-align: center;
		margin-top: 2%;
	}
	.TerpenesContent {
		display: flex;
    flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
	}
  .MoreInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
	.TerpenesLearnText {
		font-size: 150%;
	}
	.TerpenesImg {
		width: 360px;
	}
	.specTerpName {
		margin-top: 2%;
	}
	.specTerpText {
		margin-top: 2%;
		margin-bottom: 4%;
		margin-left: 1%;
		margin-right: 10%;
		font-size: 200%;
	}
	.TerpLearnButtons {
		margin-bottom: 5%;
		display: flex;
    flex-wrap: wrap;
		justify-content: center;
    align-items: center;
    width: 100%;
	}
	.TerpLearnButtonLink {
		background-color: white;
		margin-top: 2.5%;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 10px;
		color: black;
	}
	.TerpLearnButton {
		background-color: white;
		border: none;
		width: 128px;
    height: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		color: black;
	}
	.TerpLearnButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.TerpenesLearn {
		text-align: center;
		margin-top: 2%;
	}
	.TerpenesContent {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
  .MoreInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
	.TerpenesLearnText {
		font-size: 150%;
	}
	.TerpenesImg {
		width: 360px;
	}
	.specTerpName {
		margin-top: 2%;
	}
	.specTerpText {
		margin-top: 2%;
		margin-bottom: 4%;
		margin-left: 1%;
		margin-right: 10%;
		font-size: 200%;
	}
	.TerpLearnButtons {
		margin-bottom: 5%;
		display: flex;
    flex-wrap: wrap;
		justify-content: center;
    align-items: center;
    width: 768px;
	}
	.TerpLearnButtonLink {
		background-color: white;
		margin-top: 2.5%;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 20px;
		color: black;
	}
	.TerpLearnButton {
		background-color: white;
		border: none;
		width: 128px;
    height: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		color: black;
	}
	.TerpLearnButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
@media only screen and (min-width: 1024px) {
	.TerpenesLearn {
		text-align: center;
		margin-top: 2%;
	}
	.TerpenesContent {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.TerpenesLearnText {
		width: 50%;
		font-size: 150%;
	}
	.TerpenesImg {
		width: 40%;
	}
	.specTerpName {
		margin-top: 2%;
	}
	.specTerpText {
		margin-top: 2%;
		margin-bottom: 4%;
		margin-left: 1%;
		margin-right: 10%;
		font-size: 200%;
	}
	.TerpLearnButtons {
		margin-bottom: 5%;
		display: flex;
		justify-content: center;
    width: 100%;
	}
	.TerpLearnButtonLink {
		background-color: white;
		margin-top: 2.5%;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 100%;
		color: black;
	}
	.TerpLearnButton {
		background-color: white;
		border: none;
		width: 100%;
    height: 40px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 100%;
		color: black;
	}
	.TerpLearnButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
