@media only screen and (max-width: 768px) {
	.Home {
		padding-top: 20px;
		text-align: center;
	}
	.content-boxes {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
	}
	.content-box {
		margin: 10px;
		width: 100vw;
		position: relative;
	}
	.content-box-image {
		width: 100%;
		height: 100%;
	}
	.content-box-text {
		font-size: 20px;
		font-weight: 700;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: white;
		border-radius: 25px;
		padding: 5px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.Home {
		padding-top: 20px;
		text-align: center;
	}
	.content-boxes {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
	}
	.content-box {
		margin: 10px;
		width: 100vw;
		position: relative;
	}
	.content-box-image {
		width: 100%;
		height: 100%;
	}
	.content-box-text {
		font-size: 20px;
		font-weight: 700;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: white;
		border-radius: 25px;
		padding: 5px;
	}
}
@media only screen and (min-width: 1024px) {
	.Home {
		text-align: center;
		height: 100vh;
	}
	.content-boxes {
		width: 90vw;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin-top: 40px;
	}
	.content-box {
		width: 33%;
		position: relative;
	}
	.content-box-image {
		width: 100%;
		height: 100%;
	}
	.content-box-text {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;
		width: 70%;
		color: white;
		background-color: rgba(0, 0, 0, 0.452);
		font-size: 25px;
		font-weight: 700;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
	}
}
.box-link {
	text-decoration: none;
}
