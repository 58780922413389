@import url(https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap);
body {
  background-color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 768px) {
	.App {
		font-family: "Cardo", serif;
	}
	.EssentialBrandsLogo {
		width: 50%;
	}
	.content {
		margin-top: 74px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.App {
		font-family: "Cardo", serif;
	}
	.EssentialBrandsLogo {
		width: 50%;
	}
	.content {
		margin-top: 74px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}
}
@media only screen and (min-width: 1024px) {
	.App {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		font-family: "Cardo", serif;
	}
	.Header {
		width: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		background-color: white;
	}
	.Logos {
		margin-left: 0.75%;
		display: -webkit-flex;
		display: flex;
	}
	.EssentialBrandsLogo {
		width: 400px;
		height: 90px;
		margin-top: 20px;
	}
	.OtherLogos {
		margin-top: 10px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		margin-left: 20px;
		width: 600px;
		height: 120px;
	}
	.ForbiddenGardenLogo {
		width: 25%;
		height: 100%;
		background-color: rgb(107, 106, 106);
	}
	.PureOrganticLogo {
		width: 25%;
		height: 100%;
	}
	.HighProfileLogo {
		width: 25%;
		height: 100%;
	}
	.TerpeneXpressLogo {
		width: 24%;
		height: 100%;
	}
	.navbar {
		top: 0;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 65px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		box-shadow: 5px 5px 5px #888888;
	}
	.cart {
		width: 1.4vw;
		margin-right: 2vw;
		margin-left: 1vw;
	}
	.nav-button {
		background-color: white;
		border: none;
		width: 126.75px;
		height: 25px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 12px;
		color: black;
	}
	.nav-button:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f1f1f1;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}
	.dropdown-content a {
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}
	/* Change color of dropdown links on hover */
	.dropdown-content a:hover {
		background-color: #33415b;
		transition-duration: 0.6s;
		cursor: pointer;
		color: white;
	}

	/* Show the dropdown menu on hover */
	.link:hover .dropdown-content {
		display: block;
	}

	/* Change the background color of the dropdown button when the dropdown content is shown */
	.link:hover .dropbtn {
		background-color: #3e8e41;
	}
	.content {
		width: 1000px;
		background-color: #fff;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.bottom {
		width: 100%;
		background-color: #33415b;
		color: white;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
	.bottom b {
		font-size: 24px;
	}
	.bottom a {
		text-decoration: none;
		color: white;
	}
}

@media only screen and (max-width: 768px) {
	.Home {
		padding-top: 20px;
		text-align: center;
	}
	.content-boxes {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		margin-top: 40px;
	}
	.content-box {
		margin: 10px;
		width: 100vw;
		position: relative;
	}
	.content-box-image {
		width: 100%;
		height: 100%;
	}
	.content-box-text {
		font-size: 20px;
		font-weight: 700;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		background-color: white;
		border-radius: 25px;
		padding: 5px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.Home {
		padding-top: 20px;
		text-align: center;
	}
	.content-boxes {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		margin-top: 40px;
	}
	.content-box {
		margin: 10px;
		width: 100vw;
		position: relative;
	}
	.content-box-image {
		width: 100%;
		height: 100%;
	}
	.content-box-text {
		font-size: 20px;
		font-weight: 700;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		background-color: white;
		border-radius: 25px;
		padding: 5px;
	}
}
@media only screen and (min-width: 1024px) {
	.Home {
		text-align: center;
		height: 100vh;
	}
	.content-boxes {
		width: 90vw;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		margin-top: 40px;
	}
	.content-box {
		width: 33%;
		position: relative;
	}
	.content-box-image {
		width: 100%;
		height: 100%;
	}
	.content-box-text {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		height: 50%;
		width: 70%;
		color: white;
		background-color: rgba(0, 0, 0, 0.452);
		font-size: 25px;
		font-weight: 700;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		
	}
}
.box-link {
	text-decoration: none;
}

@media only screen and (max-width: 830px) {
	.AboutUs {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-align-items: center;
		        align-items: center;
		text-align: center;
	}
  .AboutUsText {
    width: 70%;
    font-size: 20px;
  }
	.image {
		width: 100%;
	}
}
@media only screen and (min-width: 830px) and (max-width: 1024px) {
	.AboutUs {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
		text-align: center;
	}
  .AboutUsText {
    width: 40%;
    font-size: 25px;
  }
	.image {
		width: 400px;
		margin-right: 5%;
		margin-left: 5%;
	}
}
@media only screen and (min-width: 1024px) {
	.AboutUs {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
		text-align: center;
	}
  .AboutUsText {
    width: 40%;
    font-size: 25px;
  }
	.image {
		width: 50%;
		margin-right: 5%;
		margin-left: 5%;
	}
}

@media only screen and (max-width: 768px) {
	.Product {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: 300px;
		height: 440px;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		-webkit-align-items: center;
		        align-items: center;
		margin-bottom: 25px;
		box-shadow: 4px 4px lightgray;
	}

	.productImage {
		max-width: 200px;
		max-height: 225px;
	}
	.product-price {
		font-weight: 700;
		font-size: 20px;
		color: #4f7dae;
	}
	.actions {
		width: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.ARButtons {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		font-size: 25px;
	}
	.ARButton {
		font-size: 25px;
		border: none;
		background-color: white;
	}
	.ARButton:hover {
		cursor: pointer;
	}
	.productButton {
		background-color: white;
		border: none;
		width: 120px;
		height: 35px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 14px;
		color: black;
		margin: 4px;
	}
	.productButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.ProductModal {
		position: absolute;
		top: 30%;
		left: 20%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		background-color: white;
		margin: 2%;
		width: 60%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.Product {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: 40%;
		height: 420px;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		-webkit-align-items: center;
		        align-items: center;
		margin-bottom: 25px;
		box-shadow: 4px 4px lightgray;
	}

	.productImage {
		max-width: 29vw;
		max-height: 225px;
	}
	.product-price {
		font-weight: 700;
		font-size: 20px;
		color: #4f7dae;
	}
	.actions {
		width: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.ARButtons {
		font-size: 150%;
	}
	.ARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.ARButton:hover {
		cursor: pointer;
	}
	.productButton {
		background-color: white;
		border: none;
		width: 80px;
		height: 20px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 10px;
		color: black;
		margin: 4px;
	}
	.productButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.ProductModal {
		position: absolute;
		top: 30%;
		left: 20%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		background-color: white;
		margin: 2%;
		width: 60%;
	}
}
@media only screen and (min-width: 1024px) {
	.Product {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: 30%;
		height: 500px;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		-webkit-align-items: center;
		        align-items: center;
		margin-bottom: 25px;
		box-shadow: 4px 4px lightgray;
	}
	.product-name {
		width: 100%;
		height: 12.5%;
		font-weight: 700;
		font-size: large;
		margin: 2px;
	}
	.product-image {
		width: 100%;
		height: 60%;
	}
	.productImage {
		max-width: 90%;
		max-height: 95%;
	}
	.product-price {
		font-weight: 700;
		font-size: large;
		color: #4f7dae;
	}
	.actions {
		width: 100%;
		height: 16%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.ARButtons {
		font-size: 150%;
	}
	.ARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.ARButton:hover {
		cursor: pointer;
	}
	.productButton {
		background-color: white;
		border: none;
		width: 50px;
		height: 50px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 0.8vw;
		color: black;
		margin: 4px;
	}
	.productButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.ProductModal {
		position: absolute;
		top: 30%;
		left: 20%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		background-color: white;
		margin: 2%;
		width: 60%;
	}
}

@media only screen and (max-width: 768px) {
	.ProductsMain {
		text-align: center;
	}
	.Products {
		margin-left: 5px;
		margin-right: 5px;
		text-align: center;
		display: -webkit-flex;
		display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-around;
		        justify-content: space-around;
		-webkit-align-items: center;
		        align-items: center;
	}
	.brand-buttons {
		display: -webkit-flex;
		display: flex;
	}
	.filters {
		margin-bottom: 2%;
	}
	.brand-button-image {
		width: 100%;
	}
	.brand-header {
		width: 300px;
		height: 50px;
		margin-bottom: 20px;
	}

	.brand-button {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 14px;
		color: black;
		margin: 4px;
	}
	.brand-button-selected {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 15px;
		color: black;
		margin: 4px;
		border-bottom: 4px solid #33415b;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.ProductsMain {
		text-align: center;
	}
	.Products {
		margin-left: 5px;
		margin-right: 5px;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-around;
		        justify-content: space-around;
	}
	.brand-buttons {
		display: -webkit-flex;
		display: flex;
	}
	.filters {
		margin-bottom: 2%;
	}
	.brand-button-image {
		width: 100%;
	}
	.brand-header {
		width: 700px;
		height: 150px;
		margin-bottom: 20px;
	}

	.brand-button {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 14px;
		color: black;
		margin: 4px;
	}
	.brand-button-selected {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 15px;
		color: black;
		margin: 4px;
		border-bottom: 4px solid #33415b;
	}
}
@media only screen and (min-width: 1024px) {
	.ProductsMain {
		text-align: center;
	}
	.Products {
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-around;
		        justify-content: space-around;
	}
	.brand-buttons {
		display: -webkit-flex;
		display: flex;
	}
	.filters {
		margin-bottom: 2%;
	}
	.brand-button-image {
		width: 100%;
	}
	.brand-header {
		width: 750px;
		height: 150px;
		margin-bottom: 20px;
	}

	.brand-button {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 14px;
		color: black;
		margin: 4px;
	}
	.brand-button-selected {
		background-color: white;
		border: none;
		width: 140px;
		height: 30px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: 15px;
		color: black;
		margin: 4px;
		border-bottom: 4px solid #33415b;
	}
	.brand-button:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}

.Invest {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.InvestText {
  font-size: 1.4em;
}
.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 5%;
}
.investInputText {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}
.investInputPhone {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}
.investInputEmail {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #45a049;
}
.FormRow {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
	.terpenes {
    padding-top: 20px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		background-color: white;
		width: 100%;
		height: 100%;
	}
	.graphics {
		position: relative;
		height: 60%;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
	}
	.text-under-graphics {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: baseline;
		        align-items: baseline;
		height: 40%;
	}
	.under-image-box {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		width: 50%;
		height: 40%;
	}
	.leaflyButton {
		background-color: white;
		border: none;
		width: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 12px;
		color: black;
	}
	.leaflyButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.terpene-type {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-name {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-price {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.selectForm {
		width: 50%; /* Full width */
		padding: 6px; /* Some padding */
		border: 1px solid #ccc; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
	.TARButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
		font-size: 150%;
	}
	.TARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.TARButton:hover {
		cursor: pointer;
	}
	.terpeneButton {
		background-color: white;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: medium;
		color: black;
		margin: 4px;
	}
	.terpeneButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}

	.terpene-bottle {
		display: block;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.name-and-price {
		height: 20%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		-webkit-align-items: flex-end;
		        align-items: flex-end;
		padding: 2%;
	}
	.image-text {
		position: absolute;
		top: 75%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		width: 20%;
		text-align: center;
	}
	.addTerpToCart {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		width: 50%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
		.terpenes {
    padding-top: 20px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		background-color: white;
		width: 100%;
		height: 100%;
	}
	.graphics {
		position: relative;
		height: 70%;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
	}
	.text-under-graphics {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: baseline;
		        align-items: baseline;
		height: 30%;
	}
	.under-image-box {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		width: 50%;
		height: 40%;
	}
	.leaflyButton {
		background-color: white;
		border: none;
		width: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 12px;
		color: black;
	}
	.leaflyButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.terpene-type {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-name {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-price {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.selectForm {
		width: 70%; /* Full width */
		padding: 6px; /* Some padding */
		border: 1px solid #ccc; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
	.TARButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
		font-size: 150%;
	}
	.TARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.TARButton:hover {
		cursor: pointer;
	}
	.terpeneButton {
		background-color: white;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: medium;
		color: black;
		margin: 4px;
	}
	.terpeneButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}

	.terpene-bottle {
		display: block;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.name-and-price {
		height: 20%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		-webkit-align-items: flex-end;
		        align-items: flex-end;
		padding: 2%;
	}
	.image-text {
		position: absolute;
		top: 75%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		width: 20%;
		text-align: center;
	}
	.addTerpToCart {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		width: 50%;
	}
}
@media only screen and (min-width: 1024px) {
	.terpenes {
    padding-top: 20px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		background-color: white;
		width: 100%;
		height: 100%;
	}
	.graphics {
		position: relative;
		height: 70%;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
	}
	.text-under-graphics {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: baseline;
		        align-items: baseline;
		height: 30%;
	}
	.under-image-box {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		width: 50%;
		height: 40%;
	}
	.leaflyButton {
		background-color: white;
		border: none;
		width: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 12px;
		color: black;
	}
	.leaflyButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
	.terpene-type {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-name {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.terpene-price {
		margin: 0;
		font-size: large;
		font-weight: 700;
	}
	.selectForm {
		width: 80%; /* Full width */
		padding: 6px; /* Some padding */
		border: 1px solid #ccc; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
	.TARButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
		font-size: 150%;
	}
	.TARButton {
		font-size: 100%;
		border: none;
		background-color: white;
	}
	.TARButton:hover {
		cursor: pointer;
	}
	.terpeneButton {
		background-color: white;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 400;
		font-size: medium;
		color: black;
		margin: 4px;
	}
	.terpeneButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}

	.terpene-bottle {
		display: block;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.name-and-price {
		height: 20%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		-webkit-align-items: flex-end;
		        align-items: flex-end;
		padding: 2%;
	}
	.image-text {
		position: absolute;
		top: 75%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		width: 20%;
		text-align: center;
	}
	.addTerpToCart {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		width: 50%;
	}
}

@media only screen and (max-width: 768px) {
	.TerpenesList {
	margin-top: 5%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.TerpeneCard {
	width: 90vw;
	height: 420px;
	margin-bottom: 2%;
}
.TerpenesTitle {
	text-align: center;
}
.type-checkboxes {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.TerpenesList {
	margin-top: 5%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.TerpeneCard {
	width: 34vw;
	height: 500px;
	margin-bottom: 2%;
}
.TerpenesTitle {
	text-align: center;
}
.type-checkboxes {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
}
@media only screen and (min-width: 1024px) {
	.TerpenesList {
	margin-top: 5%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.TerpeneCard {
	width: 30%;
	height: 600px;
	margin-bottom: 2%;
}
.TerpenesTitle {
	text-align: center;
}
.type-checkboxes {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
}



@media only screen and (max-width: 1024px) {
	.FAQ {
		margin-top: 4%;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
	}
	.LeftSide {
		display: -webkit-flex;
		display: flex;
		width: 40%;
		-webkit-justify-content: center;
		        justify-content: center;
	}
	.LeftSide h1 {
		width: 10%;
		text-align: center;
	}
	.RightSide {
		width: 60%;
    margin-right: 15px;
	}
}
@media only screen and (min-width: 1024px) {
	.FAQ {
		margin-top: 4%;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
	}
	.LeftSide {
		display: -webkit-flex;
		display: flex;
		width: 35%;
		-webkit-justify-content: center;
		        justify-content: center;
	}
	.LeftSide h1 {
		width: 10%;
		text-align: center;
	}
	.RightSide {
		width: 65%;
	}
}

@media only screen and (max-width: 768px) {
	.OurBrands {
		margin-left: 8px;
		margin-right: 8px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		text-align: center;
	}
	.Brand {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin-top: 5%;
		margin-bottom: 5%;
	}
	.BrandContent {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
	}
	#FGLogo {
		background-color: black;
	}
	.Logo {
		width: 35%;
		margin: 2%;
	}
	.BrandButtons {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		width: 80%;
	}
	.BrandButton {
		height: 40px;
		width: 250px;
		border: none;
		background-color: white;
		padding: 0;
	}
	.BrandButton:hover {
		cursor: pointer;
	}
	.BrandButtonImage {
		width: 100%;
		height: 100%;
	}
	.GeneralText {
		font-size: 20px;
	}
	.BrandContent {
		font-size: 17px;
		font-weight: 700;
	}
}
@media only screen and (min-width: 768px) {
	.OurBrands {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		text-align: center;
		margin-left: 8px;
		margin-right: 8px;
	}
	.Brand {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin-top: 5%;
		margin-bottom: 5%;
	}
	.BrandContent {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
	}
	#FGLogo {
		background-color: black;
	}
	.Logo {
		width: 35%;
		margin: 2%;
	}
	.BrandButtons {
		display: -webkit-flex;
		display: flex;
		width: 100%;
	}
	.BrandButton {
		height: 40px;
		width: 100%;
		border: none;
		background-color: white;
		padding: 0;
	}
	.BrandButton:hover {
		cursor: pointer;
	}
	.BrandButtonImage {
		width: 100%;
		height: 100%;
	}
	.GeneralText {
		font-size: 20px;
	}
	.BrandContent {
		font-size: 17px;
		font-weight: 700;
	}
}

@media only screen and (max-width: 768px) {
	.CBDLearn {
		width: 100vw;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}
	.HeaderContainer {
		max-width: 768px;
		max-height: 200px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		overflow: hidden;
	}
	.CBDContent {
		width: 100vw;
	}
	.CBDHeader {
		width: 1920px;
		height: 300px;
	}
	.MainContent {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		margin: 3%;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
	.MainImage {
		width: 500px;
	}

	.Spectrums {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
    -webkit-align-items: center;
            align-items: center;
		text-align: left;
	}
	.Spectrum {
		display: -webkit-flex;
		display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
		width: 80vw;
	}
	.Spectrum h2 {
		text-align: center;
		font-size: large;
    width: 80vw;
	}

	.CBDLearnText {
		margin-left: 1%;
		font-size: 130%;
	}

	.CBDImageContainer {
		height: 200px;
		overflow: hidden;
	}

	.ProsConsContainer {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		margin-bottom: 5%;
	}
	.ProsCons {
		width: 30%;
		display: -webkit-flex;
		display: flex;
	}
	.Pros {
		width: 50%;
	}
	.Cons {
		width: 50%;
	}
	.ProsCons li {
		text-align: left;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.CBDLearn {
		width: 768px;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}
	.HeaderContainer {
		max-width: 1024px;
		max-height: 200px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		overflow: hidden;
	}
	.CBDContent {
		width: 768px;
	}
	.CBDHeader {
		width: 1920px;
		height: 300px;
	}
	.MainContent {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		margin: 3%;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
	.MainImage {
		width: 500px;
	}

	.Spectrums {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		text-align: left;
	}
	.ImgAndText {
		height: 250px;
	}
	.Spectrums h2 {
		text-align: center;
	}

	.CBDLearnText {
		margin-left: 1%;
		font-size: 130%;
	}
	.Spectrum {
		width: 25%;
		height: 500px;
	}
	.CBDImageContainer {
		height: 200px;
		overflow: hidden;
	}

	.ProsConsContainer {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		margin-bottom: 5%;
	}
	.ProsCons {
		width: 30%;
		display: -webkit-flex;
		display: flex;
	}
	.Pros {
		width: 50%;
	}
	.Cons {
		width: 50%;
	}
	.ProsCons li {
		text-align: left;
	}
}
@media only screen and (min-width: 1024px) {
	.CBDLearn {
		width: 100%;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}
	.HeaderContainer {
		max-width: 1920px;
		max-height: 200px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		overflow: hidden;
	}
	.CBDContent {
		width: 1000px;
	}
	.CBDHeader {
		width: 1920px;
		height: 300px;
	}
	.MainContent {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		margin: 3%;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
	.MainImage {
		width: 500px;
	}

	.Spectrums {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		text-align: left;
	}
	.ImgAndText {
		height: 250px;
	}
	.Spectrums h2 {
		text-align: center;
	}

	.CBDLearnText {
		margin-left: 1%;
		font-size: 130%;
	}
	.Spectrum {
		width: 25%;
		height: 500px;
	}
	.CBDImageContainer {
		height: 200px;
		overflow: hidden;
	}

	.ProsConsContainer {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		margin-bottom: 5%;
	}
	.ProsCons {
		width: 30%;
		display: -webkit-flex;
		display: flex;
	}
	.Pros {
		width: 50%;
	}
	.Cons {
		width: 50%;
	}
	.ProsCons li {
		text-align: left;
	}
}

@media only screen and (max-width: 768px) {
	.TerpenesLearn {
		text-align: center;
		margin-top: 2%;
	}
	.TerpenesContent {
		display: -webkit-flex;
		display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-items: center;
		        align-items: center;
	}
  .MoreInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
	.TerpenesLearnText {
		font-size: 150%;
	}
	.TerpenesImg {
		width: 360px;
	}
	.specTerpName {
		margin-top: 2%;
	}
	.specTerpText {
		margin-top: 2%;
		margin-bottom: 4%;
		margin-left: 1%;
		margin-right: 10%;
		font-size: 200%;
	}
	.TerpLearnButtons {
		margin-bottom: 5%;
		display: -webkit-flex;
		display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
		-webkit-justify-content: center;
		        justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
	}
	.TerpLearnButtonLink {
		background-color: white;
		margin-top: 2.5%;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 10px;
		color: black;
	}
	.TerpLearnButton {
		background-color: white;
		border: none;
		width: 128px;
    height: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		color: black;
	}
	.TerpLearnButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.TerpenesLearn {
		text-align: center;
		margin-top: 2%;
	}
	.TerpenesContent {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-items: center;
		        align-items: center;
	}
  .MoreInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
	.TerpenesLearnText {
		font-size: 150%;
	}
	.TerpenesImg {
		width: 360px;
	}
	.specTerpName {
		margin-top: 2%;
	}
	.specTerpText {
		margin-top: 2%;
		margin-bottom: 4%;
		margin-left: 1%;
		margin-right: 10%;
		font-size: 200%;
	}
	.TerpLearnButtons {
		margin-bottom: 5%;
		display: -webkit-flex;
		display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
		-webkit-justify-content: center;
		        justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 768px;
	}
	.TerpLearnButtonLink {
		background-color: white;
		margin-top: 2.5%;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 20px;
		color: black;
	}
	.TerpLearnButton {
		background-color: white;
		border: none;
		width: 128px;
    height: 80px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		color: black;
	}
	.TerpLearnButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
@media only screen and (min-width: 1024px) {
	.TerpenesLearn {
		text-align: center;
		margin-top: 2%;
	}
	.TerpenesContent {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		-webkit-align-items: center;
		        align-items: center;
	}
	.TerpenesLearnText {
		width: 50%;
		font-size: 150%;
	}
	.TerpenesImg {
		width: 40%;
	}
	.specTerpName {
		margin-top: 2%;
	}
	.specTerpText {
		margin-top: 2%;
		margin-bottom: 4%;
		margin-left: 1%;
		margin-right: 10%;
		font-size: 200%;
	}
	.TerpLearnButtons {
		margin-bottom: 5%;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
    width: 100%;
	}
	.TerpLearnButtonLink {
		background-color: white;
		margin-top: 2.5%;
		border: none;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 100%;
		color: black;
	}
	.TerpLearnButton {
		background-color: white;
		border: none;
		width: 100%;
    height: 40px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 700;
		font-size: 100%;
		color: black;
	}
	.TerpLearnButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}

.Entourage {
  text-align: center;
}
.EntourageImage {
  width: 75%;
}
.EntourageText {
  font-size: 150%;
  margin: 1% 2% 5% 2%;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
}

.pin {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.5em;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.452);
  color: white;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}
.Locations {
  text-align: center;
  width: 80vw;
  margin-bottom: 20px;
}
.WhatWeDo {
  margin: 2%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.WhatWeDo h1 {
  text-align: center;
}
.WhatWeDoList {
  text-align: left;
}
.image-grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 68.6vh;
  text-align: center;
}
.top-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: 50%;
}
.bottom-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: 50%;
}
.top-row div {
  width: 25%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.bottom-row div {
  width: 25%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.image-grid img {
  max-width: 100%;
  max-height: 70%;
}
.WLButton {
	background-color: white;
  margin-bottom: 2%;
	border: none;
	width: 10vw;
	height: 3vh;
	transition-duration: 0.6s;
	font-family: "Cardo", serif;
	font-weight: 550;
	font-size: 0.7vw;
	color: black;
}
.WLButton:hover {
	background-color: #33415b;
	cursor: pointer;
	color: white;
}
@media only screen and (max-width: 768px) {
	.WhiteLabeling {
		text-align: center;
		margin: 2%;
		font-size: large;
	}
	.text-image1 {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		text-align: left;
		-webkit-align-items: center;
		        align-items: center;
	}
	.text-image2 {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
		text-align: left;
		-webkit-align-items: center;
		        align-items: center;
	}
	.WLImage {
		width: 300px;
		height: 200px;
	}
	.text-image img {
		margin: 1%;
	}
	.text-image p {
		margin: 1%;
	}
	.WLButton {
		background-color: white;
		margin-bottom: 2%;
		border: none;
		width: 200px;
		height: 60px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 550;
		font-size: large;
		color: black;
	}
	.WLButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.WhiteLabeling {
		text-align: center;
		margin: 2%;
		font-size: large;
	}
	.text-image1 {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		text-align: left;
		-webkit-align-items: center;
		        align-items: center;
	}
	.text-image2 {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
		text-align: left;
		-webkit-align-items: center;
		        align-items: center;
	}
	.WLImage {
		width: 500px;
		height: 300px;
	}
	.text-image img {
		margin: 1%;
	}
	.text-image p {
		margin: 1%;
	}
	.WLButton {
		background-color: white;
		margin-bottom: 2%;
		border: none;
		width: 200px;
		height: 60px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 550;
		font-size: large;
		color: black;
	}
	.WLButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}
@media only screen and (min-width: 1024px) {
	.WhiteLabeling {
		text-align: center;
		margin: 2%;
		font-size: large;
	}
	.text-image1, .text-image2 {
		display: -webkit-flex;
		display: flex;
		text-align: left;
		-webkit-align-items: center;
		        align-items: center;
	}
	.WLImage {
		width: 500px;
		height: 400px;
	}
	.text-image img {
		margin: 1%;
	}
	.text-image p {
		margin: 1%;
	}
	.WLButton {
		background-color: white;
		margin-bottom: 2%;
		border: none;
		width: 200px;
		height: 60px;
		transition-duration: 0.6s;
		font-family: "Cardo", serif;
		font-weight: 550;
		font-size: large;
		color: black;
	}
	.WLButton:hover {
		background-color: #33415b;
		cursor: pointer;
		color: white;
	}
}

.Invest {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.InvestText {
  font-size: 1.4em;
}
.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 5%;
}
.investInputText {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}
.investInputPhone {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}
.investInputEmail {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #45a049;
}
.FormRow {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
.MoreTerpenes {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.terpeneTable {
  width: 40%;
}
.terpeneTable, th, td {
  border: 1px solid black;
}
.tableHead th {
  padding-bottom: 2%;
}
.terpeneTable th {
  width: 50%;
}
.MixCalculator {
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex-wrap: none;
	        flex-wrap: none;
	-webkit-align-items: center;
	        align-items: center;
}
@media only screen and (max-width: 768px) {
	.Calculator {
		width: 100%;
		border: 1px solid black;
		padding: 2%;
		text-align: left;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.Results {
		width: 50%;
		font-size: 15px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
	.Inputs {
		font-size: 10px;
		width: 50%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.Calculator {
		width: 80%;
		border: 1px solid black;
		padding: 2%;
		text-align: left;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.Results {
		width: 50%;
		font-size: 20px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
	.Inputs {
		font-size: 15px;
		width: 50%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}
}
@media only screen and (min-width: 1024px) {
	.Calculator {
		width: 60%;
		border: 1px solid black;
		padding: 2%;
		text-align: left;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.Results {
		width: 50%;
		font-size: 25px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
	.Inputs {
		font-size: 20px;
		width: 50%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}
}

.InputBox {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	text-align: center;
}
.inputNumber {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
.decimalInput {
	font-size: 25px;
	width: auto;
	text-align: right;
	border: none;
}
.integerInput {
	font-size: 25px;
	width: auto;
	text-align: right;
	border: none;
}
.Inputs span {
	font-size: 25px;
}
.Inputs input::-webkit-outer-spin-button,
.Inputs input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.Inputs input[type="number"] {
	-moz-appearance: textfield;
}

.Result {
	display: -webkit-flex;
	display: flex;
}
.Volumes {
	width: 50%;
}
.VolumesResults {
	padding: 2px;
	width: 50%;
	border: 2px solid #556c97;
}
.CalcButton {
	margin-top: 2%;
	width: 30%;
}
.inputButton {
	border: none;
	font-size: 20px;
}

